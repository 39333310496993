<template>
  <Header></Header>
  <div class="main-height">
    <section class="step-two">
      <div class="container">
        <div class="inner-container">
          <div class="row">
            <div class="col-12">
              <p>
                Step <span class="fw-bold">2</span> of <span class="fw-bold">3</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h1 class="fw-bold mt-0">Choose a plan that's right for you</h1>
            </div>
          </div>
          <div v-if="this.userPlan != null && !this.userPlan.trial && this.userProfile.planEndDate > Date.now()" class="row">
            <div class="col-12">
              <h6 class="mt-0 coupon">Upgrade smoothly, and your remaining time will seamlessly extend into your new plan up to <strong>{{this.newPlanEndDate}}!</strong></h6>
            </div>
          </div>
          <div v-if="error.length" class="alert-danger mt-1 text-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
            {{ error }}
          </div>

          <div class="row mt-2">
            <div class="col-12">
              <form>
                <div class="plan" v-bind:key="plan.planId" v-for="plan in this.planList">
                  <PlanItem v-bind:plan_item="plan" ref="planButton" @set-plan-id="setPlanId"></PlanItem>
                  <br>
                </div>
              </form>
            </div>
          </div>

          <!-- Coupon button  -->
          <div  v-if="coupon == null" class="row my-2">
            <div class="col-12 d-flex justify-content-end">
              <img class="coupon-img" src="../assets/coupon.png" alt="">
              <a role="button" class="coupon img-fluid" @click="showModal = true">&nbsp; Add Promo Code</a>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
                <button class="btn text-white w-100" @click="doNext">
                  <span v-show="!loading">Next</span>
                  <div v-show="loading" class="button-loading">
                    <div class="spinner-border" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </button>
            </div>
          </div>
        </div>
        </div>
    </section>
  </div>

  <!-- Modal -->
  <div class="modal d-block" v-if="showModal" >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title fw-bold" id="exampleModalLongTitle">Add a Promo code</h2>
          <button role="button" class="close close-modal" data-dismiss="modal" aria-label="Close" @click="reloadSite">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <section class="coupon-page">
            <div class="row">
              <div class="col-sm-12 col-12">
                <form>
                  <div class="register-form">
                    <div class="input-group mt-1">
                      <input required="" type="text" name="text" autocomplete="off" class="input" v-model="couponCode">
                      <label class="user-label">Add Promo Code</label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div v-if="notification.length" class="alert-success mt-3 pe-3 text-success">
              {{ notification }}
            </div>
            <div v-if="modelError.length" class="alert-danger mt-3 pe-3 text-danger">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
              </svg>
              {{ modelError }}
            </div>
            <div class="row">
              <div class="col-sm-12 col-12 mt-3">
                <button @click="doAddCoupon" class="btn text-white w-100 rounded-1" data-dismiss="modal">
                  <span v-show="!modalloading">Submit</span>
                  <div v-show="modalloading" class="button-loading">
                    <div class="spinner-border" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>

  <Footer></Footer>



</template>

<script>
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PlanItem from '@/components/PlanItem.vue'
import PlanService from '@/services/plan-service'
import AuthService from "@/services/auth-service";
import UsersService from "@/services/users-service";

// import StripeService from '@/services/stripe-service'

export default defineComponent({
  name: "Plans",
  components: {
    Header,
    PlanItem,
    Footer
  },
  data() {
    return {
      error: '',
      modelError: '',
      planId: '',
      showModal:false,
      notification: '',
      couponCode: '',
      planList: [],
      userPlan: null,
      userProfile: null,
      newPlanEndDate: '',
      coupon: null,
      loading: false,
      modalloading: false
    }
  },
  methods: {
    // filter children and find buttons ( by component-type property )
    // and inactive all .
    doAccount() {
      this.$router.push('/account')
    },
    reloadSite() {
      window.location.reload();
    },
    doAddCoupon() {
      if (this.modalloading) return;
      this.modalloading = true;
      if (this.couponCode != null && this.couponCode != '') {
        UsersService.validateCoupon(this.couponCode).then((coupon) => {
          if (coupon.valid == true) {
            localStorage.setItem('coupon', JSON.stringify(coupon))
            this.modelError = ""
            this.notification = "Coupon is added!";
            window.location.reload();
            this.modalloading = false;
          } else {
            this.modelError = "Coupon is not valid!";
            this.modalloading = false;
          }
        }).catch(() => {
          this.modelError = "Coupon is not valid!";
          this.modalloading = false;
        })
      } else {
        this.modelError = "Please enter coupon code!";
        this.modalloading = false;
      }
    },
    setPlanId(planId) {
      this.planId = planId
    },
    doSignOut() {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    },
    doNext() {
      if (this.loading) return;
      this.loading = true;

      if (this.planId != '') {
        const chosenPlan = this.planList.filter(plan =>
            plan.planId === this.planId);
        if (chosenPlan[0].trial === false) {
          PlanService.insertUserPlanRequest(this.planId).then(() => {
            localStorage.setItem('chosenPlan', this.planId)
            this.$router.push('/secure-payment')
          }).catch((error) => {
            this.error = 'Error: ' + error;
            this.loading = false;
          })
        } else {
          PlanService.insertTrialUserPlanRequest(this.planId).then(() => {
            this.$router.push('/trial-activation')
          }).catch((error) => {
            this.error = 'Error: ' + error;
            this.loading = false;
          })
        }
      } else {
        this.error = "Please choose a plan";
        this.loading = false;
      }
    }
  },
  beforeMount() {
    // prevent direct navigation bugs
    if (localStorage.getItem('userProfile') == null
        || localStorage.getItem('planList') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    }

    if (localStorage.getItem('planList')) this.planList = JSON.parse(localStorage.getItem('planList'));
    if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'));
    if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'));
    if (this.userProfile) this.newPlanEndDate = new Date(this.userProfile.planEndDate + 31536000000).toLocaleDateString()
    if (localStorage.getItem('coupon')) this.coupon = JSON.parse(localStorage.getItem('coupon'))

    if (this.userPlan != null) {
      this.planList = this.planList.filter(plan => !plan.trial)
      if (this.userProfile.planEndDate < Date.now() || (this.userPlan.trial && this.userProfile.trialOverallTime <= 0)) {
        this.error = "Your plan has been expired. Please choose a plan from below to avail unlimited live cricket for a year."
      } else if (this.userPlan.trial && this.userProfile.trialTodayTime <= 0) {
        let d = new Date();
        d.setHours(24, 0, 0, 0);
        let untilTomorrow = Math.abs(Math.floor((Date.now() - d) / 1000 / 60 / 60))
        this.error = "You've availed your 10 minutes trial allowance in 24 hours. Please visit again in " + untilTomorrow + " hours or choose a plan from below to enjoy unlimited live cricket."
      }
    }
  },
})
</script>

<style>
@import '../assets/style.css';
</style>
