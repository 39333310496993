<template>

  <div class="d-flex radio-toolbar" @click="toggleIsClicked(plan_item.planId)">
    <input v-if="plan_item.status" type="radio" :id="'radioBanana'+plan_item.planId" name="radioFruit" value="banana" required>
    <input v-else type="radio" :id="'radioBanana'+plan_item.planId" name="radioFruit" value="banana" disabled>
    <label class="radio-text w-100" :for="'radioBanana'+plan_item.planId">
      <div class="row my-1">
        <div class="col-8 pe-0">
          <p class="fw-bold">{{plan_item.planName}}</p>
          <p class="text-secondary">{{plan_item.description}}</p>
        </div>
        <div class="col-4 ps-0">
          <p v-if="plan_item.price == 0" class="text-end fw-bold"><span>Free</span></p>
          <p v-else class="text-end fw-bold">
            <span v-if="coupon != null && coupon.valid == true" style="text-decoration:line-through">${{plan_item.price}}</span>
            <span v-else>US ${{plan_item.price}}</span>
            <span v-if="coupon != null && coupon.valid == true" style="color: red">&nbsp;&nbsp;US ${{((plan_item.price * coupon.percentage)/100).toFixed(1)}}</span>
          </p>
          <p class="text-end text-secondary">{{plan_item.duration}}</p>
        </div>
      </div>
    </label>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent( {
  name: "PlanItem",
  props : ["plan_item"],
  data() {
    return {
      coupon: null,
    }
  },
  beforeMount() {
    if (localStorage.getItem('coupon')) this.coupon = JSON.parse(localStorage.getItem('coupon'))
  },
  methods: {
    toggleIsClicked: function (planId) {
      this.$emit('set-plan-id', planId)
    }
  }
})
</script>

<style>
@import '../assets/style.css';
</style>
